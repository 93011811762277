













import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { ListingCategory, ListingStatus, MethodOfSale } from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class BusinessPropertiesForSale extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  private listingFilterForHighlighted: ListingFilter | null = null;

  mounted() {
    this.listingFilter = new ListingFilter();
    this.listingFilter.Categories = [ListingCategory.Business];
    this.listingFilter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.Both, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.ForSale, MethodOfSale.Offers];

    this.listingFilterForHighlighted = new ListingFilter({
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
    });
    this.listingFilterForHighlighted.Categories = [ListingCategory.Commercial, ListingCategory.CommercialLand];
    this.listingFilterForHighlighted.IsHighlightListing = true;
  }
}
